exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-workshop-index-js": () => import("./../../../src/pages/workshop/index.js" /* webpackChunkName: "component---src-pages-workshop-index-js" */),
  "component---src-pages-workshop-mbira-js": () => import("./../../../src/pages/workshop/mbira.js" /* webpackChunkName: "component---src-pages-workshop-mbira-js" */),
  "component---src-pages-workshop-rose-window-js": () => import("./../../../src/pages/workshop/rose-window.js" /* webpackChunkName: "component---src-pages-workshop-rose-window-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */)
}

